import React from 'react';
import cls from 'classnames';

import { Box } from 'wui/Box';

import { classes } from './CardContent.st.css';

interface ICardContentProps extends React.ComponentProps<typeof Box> {}

export function CardContent({
  children,
  className,
  ...rest
}: ICardContentProps) {
  return (
    <Box
      padding="SP6"
      direction="vertical"
      className={cls(classes.root, className)}
      {...rest}
    >
      {children}
    </Box>
  );
}
