import React from 'react';
import cls from 'classnames';
import { Tag, TagSize, TagSkin } from 'wix-ui-tpa';
import { useSelector } from 'react-redux';

import type { ITopic } from 'api/topics/types';
import { EFilterKeys } from 'api/feed/constants';

import { UISref, useCurrentStateAndParams } from 'common/router';

import { selectGroupSlugById } from 'store/groups/selectors';

import { classes } from './Topic.st.css';

interface ITopicProps {
  topic: ITopic;
  groupId?: string;
  withCounter?: boolean;

  onClick?: (topic: ITopic) => void;
}

export function Topic(props: ITopicProps) {
  const { topic, withCounter, groupId } = props;

  const { params } = useCurrentStateAndParams();
  const slug = useSelector(selectGroupSlugById(groupId as string));

  const isActive = params[EFilterKeys.TOPICS] === topic.id;

  return (
    <UISref
      state="group.discussion.feed"
      params={{ [EFilterKeys.TOPICS]: topic.id, slug }}
    >
      <a className={cls(classes.root, { [classes.active]: isActive })}>
        <Tag
          size={TagSize.small}
          onClick={handleClick}
          className={classes.tag}
          skin={isActive ? TagSkin.solid : TagSkin.light}
        >
          {!withCounter
            ? topic.displayName
            : `${topic.displayName} (${topic.count})`}
        </Tag>
      </a>
    </UISref>
  );

  function handleClick() {
    props.onClick?.(topic as ITopic);
  }
}
