import React from 'react';
import cls from 'classnames';

import { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { Typography } from '../Typography';

import { classes } from './CardHeader.st.css';
import { Box } from 'wui/Box';

interface ICardHeaderProps extends TPAComponentProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;

  titleProps?: React.ComponentProps<typeof Typography>;
  subtitleProps?: React.ComponentProps<typeof Typography>;
}

export function CardHeader(props: ICardHeaderProps) {
  const { titleProps, subtitleProps } = props;

  let title: React.ReactElement;
  let subtitle: React.ReactElement;

  if (props.title && (props.title as React.ReactElement).type !== Typography) {
    title = (
      <Typography
        variant="p2-16"
        as={typeof props.title === 'string' ? 'p' : 'div'}
        {...titleProps}
      >
        {props.title}
      </Typography>
    );
  } else {
    title = props.title as React.ReactElement;
  }

  if (
    props.subtitle &&
    (props.subtitle as React.ReactElement).type !== Typography
  ) {
    subtitle = (
      <Typography
        noWrap
        secondary
        variant="p2-14"
        as={typeof props.subtitle === 'string' ? 'p' : 'div'}
        {...subtitleProps}
      >
        {props.subtitle}
      </Typography>
    );
  } else {
    subtitle = props.subtitle as React.ReactElement;
  }
  return (
    <Box
      direction="vertical"
      width="100%"
      className={cls(classes.root, props.className)}
    >
      {title}
      {subtitle}
    </Box>
  );
}

CardHeader.displayName = 'CardHeader';
