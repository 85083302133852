import React, { useState } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { ActionsMenuLayout } from 'wix-ui-tpa';
import { Drawer } from '../Drawer';
import { Popover } from '../Popover';

import { classes } from './Menu.st.css';

interface IProps {
  element: React.ReactElement;
  children: React.ReactElement[];

  drawerProps?: Partial<React.ComponentProps<typeof Drawer>>;
  popoverProps?: Partial<React.ComponentProps<typeof Popover>>;
}

export function Menu(props: IProps) {
  const { children, element } = props;
  const { isMobile } = useEnvironment();

  const [isOpen, setIsOpen] = useState(false);

  const Element = React.cloneElement(element, {
    ...element.props,
    onClick: handleOpen,
  });

  const Content = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      ...child.props,
      onClick: handleChildClick(child.props.onClick),
    }),
  );

  if (isMobile) {
    return (
      <>
        {Element}
        <Drawer
          bw
          isOpen={isOpen}
          anchor="bottom"
          onClose={handleClose}
          {...props.drawerProps}
        >
          <ActionsMenuLayout className={classes.menu}>
            {Content}
          </ActionsMenuLayout>
        </Drawer>
      </>
    );
  }

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      content={
        <ActionsMenuLayout className={classes.menu}>
          {Content}
        </ActionsMenuLayout>
      }
      {...props.popoverProps}
    >
      {Element}
    </Popover>
  );

  function handleChildClick(onClick?: Function) {
    return function (event: React.MouseEvent<HTMLButtonElement>) {
      onClick?.(event);
      handleClose();
    };
  }

  function handleOpen() {
    setIsOpen(true);
    element.props.onClick?.();
  }

  function handleClose() {
    setIsOpen(false);
  }
}

Menu.displayName = 'Menu';

export const MenuItem = ActionsMenuLayout.Item;
export const MenuDivider = ActionsMenuLayout.Divider;
