import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { groupsRequestJoinAGroup } from '@wix/bi-logger-groups/v2';

import { Info } from '@wix/wix-ui-icons-common/on-stage';
import { Text } from 'wix-ui-tpa';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { GroupMembershipButton } from 'common/components/GroupMembership';
import { BIUserEntry } from 'common/bi-logger/types';

import { selectGroupPrivacyStatus } from 'store/groups/selectors';

import { classes, st } from './SuggestedGroupLabel.st.css';

interface SuggestedGroupLabelProps extends TPAComponentProps {
  groupId: string;
}

export const SuggestedGroupLabel: React.FC<SuggestedGroupLabelProps> = (
  props,
) => {
  const { t } = useTranslation();
  const privacyStatus = useSelector(selectGroupPrivacyStatus(props.groupId));

  return (
    <div className={st(classes.root, {}, props.className)}>
      <Text className={classes.label}>
        <Info />
        {t('groups-web.discussion.feed.suggested-group.post-label.info')}
      </Text>
      <div>
        <GroupMembershipButton
          secondary
          groupId={props.groupId}
          bi={groupsRequestJoinAGroup({
            group_id: props.groupId,
            origin: 'public_feed',
            userEntry: BIUserEntry.SITE,
            type: (privacyStatus || '').toLowerCase(),
          })}
        />
      </div>
    </div>
  );
};
