import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { Avatar, AvatarSize, Text, TextButton } from 'wix-ui-tpa';

import type { IFeedItem } from 'api/feed/types';
import { Role as GroupRole } from '@wix/ambassador-social-groups-v2-group-role/types';
import { selectGroupSlugById } from 'store/groups/selectors';

import { UISref } from 'common/router';
import { useRelativeTimeFormatter } from 'common/hooks';
import { skeleton } from 'common/components/Skeleton';
import { MemberBadges } from 'common/components/MemberBadges';
import { PrivateProfileHint } from 'common/components/PrivateProfileHint';

import { AdminBadge } from 'Group/Widget/MembersPage/GroupMembers/Member';
import settingsParams from 'Group/settingsParams';

import { ActivityStatus } from '../ActivityPost';

import { AUTHOR_INFO_NAME } from './dataHooks';

import { st, classes } from './AuthorInfo.st.css';

interface AuthorInfoProps {
  item: IFeedItem;
  showGroupName?: boolean;
}

export const AuthorInfo: React.FC<AuthorInfoProps> = (props) => {
  const { item, showGroupName } = props;

  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const rtf = useRelativeTimeFormatter();

  const { createdBy } = item;
  const isAdmin = createdBy.role === GroupRole.ADMIN;

  const groupId = item.applicationContext?.contextId as string;

  const showBadges =
    settings.get(settingsParams.showMembersBadges) && !isMobile;
  const slug = useSelector(selectGroupSlugById(groupId));

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <PrivateProfileHint disabled={!createdBy.isPrivate}>
        <UISref
          state="members.profile"
          disabled={!createdBy.memberId}
          params={{ memberId: createdBy.memberId }}
        >
          <a target="_blank" rel="noreferrer">
            <Avatar
              src={createdBy.imageUrl}
              className={classes.avatar}
              size={isMobile ? AvatarSize.medium : AvatarSize.large}
            />
          </a>
        </UISref>
      </PrivateProfileHint>

      <div className={classes.avatarDivider} />
      <div>
        <PrivateProfileHint disabled={!createdBy.isPrivate}>
          <UISref
            state="members.profile"
            disabled={!createdBy.memberId}
            params={{ memberId: createdBy.memberId }}
          >
            <a target="_blank" rel="noreferrer">
              <TextButton className={classes.name} data-hook={AUTHOR_INFO_NAME}>
                {createdBy.name || t('groups-web.member.anonymous')}
              </TextButton>
            </a>
          </UISref>
        </PrivateProfileHint>
        {item.activity || showGroupName ? (
          <>
            <div className={classes.titleDivider} />
            <ActivityStatus
              activity={item.activity}
              groupId={showGroupName ? groupId : undefined}
            />
          </>
        ) : null}

        <div className={classes.description}>
          <AdminBadge isAdmin={isAdmin} />
          <UISref
            state="group.discussion.post"
            params={{ slug, feedItemId: item.feedItemId }}
          >
            <Text tagName="a" className={classes.timestamp}>
              {item.createdAt && rtf(item.createdAt)}
            </Text>
          </UISref>

          {showBadges ? <MemberBadges badges={item.createdBy.badges} /> : null}
        </div>
      </div>
    </div>
  );
};

export function AuthorInfoSkeleton() {
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <div>
        <Avatar
          size={isMobile ? AvatarSize.medium : AvatarSize.large}
          className={cls(classes.avatar, skeleton)}
        />
      </div>
      <div className={classes.avatarDivider} />
      <div>
        <Text className={skeleton}>Lorem ipsum dolor sit.</Text>
        <div className={classes.titleDivider} />
        <div>
          <Text className={skeleton}>Lorem ipsum dolor.</Text>
        </div>
      </div>
    </div>
  );
}
