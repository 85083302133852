import React from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { EGroupPartition } from 'api/groups/types';
import { selectGroups } from 'store/groups/selectors';
import { EGroupsNamespace } from 'store/groups/constants';

import { Fade } from 'wui/Fade';
import { List } from 'wui/List';
import { Show } from 'wui/Show';

import { EmptyGroupList } from '../../EmptyGroupList';

import { SELECT_GROUP_MODAL_GROUP_CARD } from './dataHooks';
import { GroupListItemSkeleton, GroupListItem } from './GroupListItem';

type JoinedGroupsProps = {
  title: string;

  onReset(): void;
  onLoadMore(): void;
  onSelect(groupId: string): void;
};

export function JoinedGroups(props: JoinedGroupsProps) {
  const { onLoadMore, onReset, onSelect, title } = props;

  const { groups, meta, status } = useSelector(
    selectGroups(EGroupsNamespace.JOINED_DIALOG),
  );

  if (status.fetch?.loading) {
    return (
      <List gap="SP3" disablePadding>
        {getPlaceholders(10)}
      </List>
    );
  }

  if (!groups.length) {
    return (
      <EmptyGroupList
        variant="section"
        onReset={onReset}
        filters={{ title }}
        paperProps={{ bw: true }}
        partition={EGroupPartition.JOINED}
      />
    );
  }

  return (
    <InfiniteScroll
      initialLoad={false}
      useWindow={false}
      hasMore={!!meta.total && meta.total > groups.length}
      loadMore={onLoadMore}
      threshold={200}
    >
      <List gap="SP3" disablePadding>
        {groups.map((group) => (
          <GroupListItem
            group={group}
            key={`group-to-post-in-${group.id}`}
            data-hook={SELECT_GROUP_MODAL_GROUP_CARD}
            onClick={onSelect}
          />
        ))}
        <Show if={status.fetchMore?.loading}>{getPlaceholders(10)}</Show>
      </List>
    </InfiniteScroll>
  );
}

function getPlaceholders(count: number) {
  return (
    <>
      {new Array(count).fill(0).map((_, index) => (
        <Fade key={index}>
          <GroupListItemSkeleton />
        </Fade>
      ))}
    </>
  );
}
