import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { groupsSelectGroupFromList } from '@wix/bi-logger-groups/v2';

import { TextButton, TextButtonPriority, ImageResizeOptions } from 'wix-ui-tpa';

import { groupsSettingsParams as settingsParams } from 'settings/groups/settings';
import { useSettings } from '@wix/tpa-settings/react';

import type { IGroup } from 'store/groups';

import { Box } from 'wui/Box';
import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';
import { ListItemIcon } from 'wui/ListItemIcon';

import { UISref } from 'common/router';
import { GroupInfo } from 'common/components/GroupInfo';
import { AdminBadge } from 'common/components/AdminBadge';
import { GroupImage } from 'common/components/GroupImage';
import { BIUserEntry } from 'common/bi-logger/types';

import { ImageRatio } from 'settings/consts';

import { classes as theme } from 'Groups/styles.st.css';
import { classes } from './GroupListItem.st.css';

interface IGroupListItemProps {
  group: IGroup;
}

export function JoinedGroupListItem(props: IGroupListItemProps) {
  const { group } = props;

  const { isMobile } = useEnvironment();
  const settings = useSettings();

  if (isMobile) {
    return (
      <ListItem disablePadding>
        <Show if={settings.get(settingsParams.showImage)}>
          <ListItemIcon>
            <Box width={40} height={40}>
              <GroupImage
                width={40}
                height={40}
                image={group.coverImage?.image}
                resize={ImageResizeOptions.cover}
                aspectRatio={ImageRatio.square}
              />
            </Box>
          </ListItemIcon>
        </Show>
        <ListItemText
          title={
            <UISref
              state="group"
              params={{ slug: group.slug }}
              bi={groupsSelectGroupFromList({
                groupId: group.id as string,
                origin: 'new_layout_groups_sidebar_my_groups',
                userEntry: BIUserEntry.SITE,
              })}
            >
              <TextButton as="a" priority={TextButtonPriority.secondary}>
                {group.name}
              </TextButton>
            </UISref>
          }
          titleProps={{ noWrap: true }}
          subtitleProps={{ noWrap: true }}
          subtitle={<GroupInfo bw={isMobile} groupId={group.id as string} />}
        />
      </ListItem>
    );
  }

  return (
    <ListItem disablePadding>
      <Show if={settings.get(settingsParams.showImage)}>
        <ListItemIcon>
          <Box width={40} height={40}>
            <GroupImage
              width={40}
              height={40}
              image={group.coverImage?.image}
              resize={ImageResizeOptions.cover}
              aspectRatio={ImageRatio.square}
            />
          </Box>
        </ListItemIcon>
      </Show>
      <ListItemText
        title={
          <Box>
            <Typography
              variant="p2-16"
              noWrap
              className={cls(classes.title, theme.oneSpaceAfter)}
            >
              <UISref
                state="group"
                params={{ slug: group.slug }}
                bi={groupsSelectGroupFromList({
                  groupId: group.id as string,
                  origin: 'new_layout_groups_sidebar_my_groups',
                  userEntry: BIUserEntry.SITE,
                })}
              >
                <TextButton as="a" priority={TextButtonPriority.secondary}>
                  {group.name}
                </TextButton>
              </UISref>
            </Typography>
            <AdminBadge groupId={group.id as string} />
          </Box>
        }
        subtitle={<GroupInfo groupId={group.id as string} />}
      />
    </ListItem>
  );
}

JoinedGroupListItem.displayName = 'JoinedGroupListItem';
