import React from 'react';
import cls from 'classnames';

import {
  Tabs as TPATabs,
  TabsAlignment,
  TabsSkin,
  TabsVariant,
} from 'wix-ui-tpa';

import { classes } from './Tabs.st.css';

interface ITabsProps
  extends Omit<
    React.ComponentProps<typeof TPATabs>,
    'activeTabIndex' | 'onTabClick'
  > {
  value: string;

  onChange: (id: string) => void;
}

export function Tabs(props: ITabsProps) {
  const [activeTabIndex, setActiveTabIndex] = React.useState(
    props.items.findIndex((item) => item.id === props.value),
  );

  React.useEffect(() => {
    const index = props.items.findIndex((item) => item.id === props.value);

    setActiveTabIndex(index);
  }, [props.value]);

  return (
    <TPATabs
      {...props}
      className={cls(classes.root, props.className)}
      activeTabIndex={activeTabIndex}
      onTabClick={handleTabClick}
    />
  );

  function handleTabClick(index: number) {
    const active = props.items[index];

    setActiveTabIndex(index);

    return props.onChange(active.id as string);
  }
}

export { TabsAlignment, TabsSkin, TabsVariant };
