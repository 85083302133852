import { createSelector } from 'reselect';

import type { IRootState } from 'store/types';
import { selectCanCreateGroup } from 'store/application/selectors';

export const selectCreationStatus = createSelector(
  (state: IRootState) => state,
  selectCanCreateGroup,
  (state, canCreate) =>
    canCreate ? state.groups.create : state.groupRequests.statuses.create,
);
