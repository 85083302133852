import React from 'react';
import { useSelector } from 'react-redux';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import type { DraftContent } from '@wix/ricos-common';
import { safeJsonParse } from 'wix-rich-content-common';
import { EditorEventsContext } from 'wix-rich-content-editor-common/libs/EditorEventsContext';
import type { RicosEditorType } from 'ricos-editor';

import type { IFeedItem } from 'api/feed/types';
import type { ITopic } from 'api/topics/types';

import type { IRootState } from 'store/types';
import {
  selectDidItemChange,
  selectDidItemCreate,
  selectDraft,
  selectIsItemCreating,
  selectIsItemUpdating,
} from 'store/feed/selectors';

import { Modal, ModalSkin } from 'common/components/Modal';
import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { RichContentEditor } from 'Group/Widget/RichContent/loadable';
import { PRESETS } from 'Group/Widget/RichContent/types';

import dataHooks from './dataHooks';

import { PostEditorDesktop, PostEditorMobile } from './layouts';

import { classes, st } from './PostEditorModal.st.css';

interface IPostEditorModalProps {
  isOpen?: boolean;
  groupId: string;
  feedItem?: IFeedItem;
  topic?: ITopic;
  className?: string;
  showGroupName?: boolean;
  cancelButtonText?: string;

  onSubmit(content: DraftContent, topicIds: string[]): void;
  onClose(): void;
  // custom cancel click
  onCancel?(): void;
  onRefChange?(node: RicosEditorType | null): void;
}

export const PostEditorModal: React.FC<IPostEditorModalProps> = (props) => {
  const { feed$ } = useController();

  const { isOpen, feedItem, groupId } = props;
  const { isMobile, isSSR } = useEnvironment();
  const { t } = useTranslation();
  const editor = React.useContext(EditorEventsContext);

  const [editorDomNode, setEditorDomNode] =
    React.useState<RicosEditorType | null>(null);
  const onEditorRefChange = React.useCallback((node) => {
    setEditorDomNode(node);
    props.onRefChange?.(node);
  }, []);

  const [isEditorBusy, setIsEditorBusy] = React.useState(false);
  const [topics, setTopics] = React.useState<ITopic[]>([]);
  const [content, setContent] = React.useState<DraftContent | undefined>();

  const Layout = isMobile ? PostEditorMobile : PostEditorDesktop;

  const isCreating = useSelector(selectIsItemCreating);
  const didCreate = useSelector(selectDidItemCreate);
  const draft = useSelector(selectDraft);

  const isUpdating = useSelector((state: IRootState) =>
    selectIsItemUpdating(state, feedItem?.feedItemId as string),
  );

  const didUpdate = useSelector((state: IRootState) =>
    selectDidItemChange(state, feedItem?.feedItemId as string),
  );

  const isLoading = isCreating || isUpdating;

  const { isEmpty, isContentChanged } = editorDomNode?.getContentTraits() || {};

  const isValid = isContentChanged || !isEmpty;

  React.useEffect(() => {
    const preselected = props.topic ? [props.topic] : [];

    if (isOpen) {
      setContent(parseContent());
      setTopics(feedItem?.entity.topics || preselected);
    }
  }, [isOpen, props.topic?.id]);

  // Wix OOI ¯\_(ツ)_/¯
  useDidUpdate(() => {
    if (feedItem?.feedItemId) {
      if (didUpdate) {
        handleClose();
      }

      return;
    }

    if (didCreate) {
      feed$.updateDraft(undefined);
      handleClose();
    }
  }, [didUpdate, didCreate]);

  return (
    <Modal
      manualFocus
      isOpen={isOpen}
      onClose={closeAndSaveDraft}
      skin={ModalSkin.NEW_POST}
    >
      <div
        className={st(classes.root, { mobile: isMobile }, props.className)}
        data-hook={dataHooks.root}
      >
        <Layout
          groupId={groupId}
          disabled={isEditorBusy || !isValid}
          isLoading={isLoading}
          topics={topics}
          feedItem={feedItem}
          onCancel={handleCancelClick}
          onTopicsChange={setTopics}
          onSubmit={handleSubmit}
          showGroupName={props.showGroupName}
          cancelButtonText={props.cancelButtonText}
        >
          <RichContentEditor
            bw
            autoFocus
            ref={onEditorRefChange}
            usage="NewPostModal"
            groupId={groupId}
            placeholder={t('groups-web.discussion.new-post.placeholder')}
            parentClass={classes.root}
            contentId={feedItem?.feedItemId}
            preset={PRESETS.EDITOR}
            content={content}
            onChange={(content) => setContent(content as DraftContent)}
            onBusyChange={setIsEditorBusy}
          />
        </Layout>
      </div>
    </Modal>
  );

  async function handleSubmit() {
    const content = await editor.publish();

    props.onSubmit(
      content,
      topics.map((topic) => topic.id as string),
    );
  }

  function parseContent() {
    const { feedItem } = props;

    if (feedItem) {
      return safeJsonParse(
        feedItem.entity?.body?.content as string,
      ) as DraftContent;
    }

    return draft;
  }

  function handleClose() {
    setContent(undefined);
    setTopics([]);
    props.onClose();
  }

  function saveDraft() {
    if (!feedItem) {
      feed$.updateDraft(content);
    }
  }

  function closeAndSaveDraft() {
    saveDraft();
    handleClose();
  }

  function handleCancelClick() {
    if (props.onCancel) {
      saveDraft();
      props.onCancel();
    } else {
      closeAndSaveDraft();
    }
  }
};
