import React from 'react';

import { ListItem } from 'wui/ListItem';
import { ListItemButton } from 'wui/ListItemButton';
import { ListItemText } from 'wui/ListItemText';
import { ListItemIcon } from 'wui/ListItemIcon';
import { Skeleton } from 'wui/Skeleton';

export function GroupListItemSkeleton() {
  return (
    <ListItem disablePadding>
      <ListItemIcon>
        <Skeleton variant="rect" width={40} height={40} />
      </ListItemIcon>
      <ListItemText
        title={<Skeleton variant="text" width="80%" />}
        subtitle={<Skeleton variant="text" width="90%" />}
      />
    </ListItem>
  );
}

GroupListItemSkeleton.displayName = 'GroupListItemSkeleton';
