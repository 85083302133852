import React from 'react';
import { useSelector } from 'react-redux';

import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';
import {
  selectGroup,
  selectMembersWithCountLabel,
  selectCanApproveMembers,
} from 'store/groups/selectors';
import { groupsSettingsParams } from 'settings/groups';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';

import { UISref } from 'common/router';

import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { Box } from 'wui/Box';

import { AdminBadge } from 'common/components/AdminBadge';

import { getGroupPrivacyLabel } from './helpers';
import { GROUP_INFO_MEMBERS_COUNT } from './dataHooks';

import { classes as theme } from 'Groups/styles.st.css';
import { classes } from './GroupInfo.st.css';

interface IGroupInfoProps extends React.ComponentProps<typeof Box> {
  bw?: boolean;
  groupId: string;

  showMiscellaneous?: boolean;
}

export function GroupInfo({
  bw,
  groupId,
  showMiscellaneous,
  className,
  ...boxProps
}: IGroupInfoProps) {
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const group = useSelector(selectGroup(groupId));
  const memberLabel = useSelector(selectMembersWithCountLabel(groupId));
  const canApproveMembers = useSelector(selectCanApproveMembers(groupId));

  const privacy = getGroupPrivacyLabel(group.privacyStatus);
  const showPrivacy = settings.get(
    groupsListWidgetSettingsParams.showGroupType,
  );

  const showMembersCount = settings.get(groupsSettingsParams.showMemberCount);
  const pendingMembersCount = group.pendingMembersCount;

  if (
    isMobile &&
    pendingMembersCount &&
    canApproveMembers &&
    showMiscellaneous
  ) {
    return (
      <UISref
        state="group.members"
        params={{ slug: group.slug, expandJoinedRequests: true }}
      >
        <TextButton as="a" priority={TextButtonPriority.primary}>
          {t('groups-web.group-list.pending-members.count_icu', {
            count: pendingMembersCount,
          })}
        </TextButton>
      </UISref>
    );
  }

  return (
    <Box gap="SP1" {...boxProps}>
      <Show
        if={
          isMobile &&
          showMiscellaneous &&
          settings.get(groupsSettingsParams.showAdminBadge)
        }
      >
        <AdminBadge groupId={groupId} />
      </Show>
      <Stack
        separator={<span className={classes.separator}>&middot;</span>}
        className={bw ? undefined : theme.groupInfo}
        truncate={true}
      >
        <Show if={showPrivacy}>
          <span>{t(privacy)}</span>
        </Show>
        <Show if={showMembersCount}>
          <span data-hook={GROUP_INFO_MEMBERS_COUNT}>
            {memberLabel.isCustom
              ? `${group.membersCount ?? 0} ${memberLabel.label}`
              : t(memberLabel.label, {
                  formattedCount: group.membersCount ?? 0,
                })}
          </span>
        </Show>
        <Show
          if={
            !!(
              group.pendingMembersCount &&
              canApproveMembers &&
              showMiscellaneous
            )
          }
        >
          <UISref
            state="group.members"
            params={{ slug: group.slug, expandJoinedRequests: true }}
          >
            <TextButton as="a" priority={TextButtonPriority.primary}>
              {t('groups-web.group-list.pending-members.count_icu', {
                count: pendingMembersCount ?? 0,
              })}
            </TextButton>
          </UISref>
        </Show>
      </Stack>
    </Box>
  );
}

GroupInfo.displayName = 'GroupInfo';

GroupInfo.defaultProps = {
  showMiscellaneous: true,
};
