import React from 'react';

import { Paper } from '../Paper';

import { classes } from './Card.st.css';
import cls from 'classnames';

interface ICardProps extends React.ComponentProps<typeof Paper> {}

export function Card({ className, children, ...rest }: ICardProps) {
  return (
    <Paper className={cls(classes.root, className)} {...rest}>
      {children}
    </Paper>
  );
}

Card.displayName = 'Card';
