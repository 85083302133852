import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { IFeedItem } from 'api/feed';

import { classes } from './TotalComments.st.css';

interface TotalCommentsProps {
  onClick(): void;
  item: IFeedItem;
}

export const TotalComments: React.FC<TotalCommentsProps> = ({
  onClick,
  item,
}) => {
  const { t } = useTranslation();
  return (
    <span onClick={onClick}>
      <Text typography={TextTypography.listText} className={classes.root}>
        {t('groups-web.discussion.feed.total-comments_icu', {
          count: item.comments?.total || 0,
        })}
      </Text>
    </span>
  );
};
