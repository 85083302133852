import React from 'react';
import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { TransitionGroup, SwitchTransition } from 'react-transition-group';

import type { PagingMetadata } from '@wix/ambassador-social-groups-v2-group/types';
import type { IGroup, IGroupListOperationStatus } from 'store/groups';

import { ChevronDown as ChevronDownIcon } from '@wix/wix-ui-icons-common/on-stage';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { List } from 'wui/List';
import { Fade } from 'wui/Fade';
import { Show } from 'wui/Show';
import { ErrorState } from 'wui/ErrorState';

import { GroupListItem, GroupListItemSkeleton } from './GroupListItem';

function getPlaceholders(count: number) {
  return new Array(count).fill(0).map((_, index) => (
    <Fade key={index}>
      <GroupListItemSkeleton />
    </Fade>
  ));
}

interface IGroupListProps {
  groups: IGroup[];
  meta: PagingMetadata;
  status: IGroupListOperationStatus;

  emptyState: React.ReactElement;

  onRetry(): void;
  onLoadMore?(): void;
}

export function GroupList(props: IGroupListProps) {
  const { groups, meta, status } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const hasMore = groups.length < (meta.total as number);

  return (
    <ErrorMonitorBoundary
      fallback={
        <ErrorState
          variant="section"
          paperProps={{ bw: isMobile }}
          subtitle={t('groups-web.toast.error.groups.query')}
        />
      }
    >
      <SwitchTransition>
        {(() => {
          if (status.fetch?.loading) {
            return (
              <Fade key="loading">
                <List disableGutters={isMobile} gap={isMobile ? 'SP4' : 'SP5'}>
                  {getPlaceholders(5)}
                </List>
              </Fade>
            );
          }

          if (status.fetch?.error) {
            return (
              <Fade key="error">
                <ErrorState
                  variant="section"
                  onRetry={props.onRetry}
                  subtitle={t('groups-web.toast.error.groups.query')}
                  paperProps={{ bw: isMobile }}
                />
              </Fade>
            );
          }

          if (!groups.length) {
            return (
              <Fade key="empty">
                <>{props.emptyState}</>
              </Fade>
            );
          }

          return (
            <Fade key="list">
              <>
                <List disableGutters={isMobile} gap={isMobile ? 'SP4' : 'SP5'}>
                  <TransitionGroup component={null}>
                    {groups.map((group) => (
                      <Fade key={group.id}>
                        <GroupListItem group={group} />
                      </Fade>
                    ))}
                  </TransitionGroup>
                  <Show if={status.fetchMore?.loading}>
                    {getPlaceholders(5)}
                  </Show>
                </List>

                <Show if={hasMore && !!props.onLoadMore}>
                  <TextButton
                    onClick={props.onLoadMore}
                    prefixIcon={<ChevronDownIcon />}
                    priority={TextButtonPriority.primary}
                  >
                    {t('groups-web.showMore')}
                  </TextButton>
                </Show>
              </>
            </Fade>
          );
        })()}
      </SwitchTransition>
    </ErrorMonitorBoundary>
  );
}

GroupList.displayName = 'GroupList';
GroupList.defaultProps = {
  batchLength: 5,
};
