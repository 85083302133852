import React from 'react';
import cls from 'classnames';

import { Popover as PopoverTPA } from 'wix-ui-tpa';

import { usePortalContainer } from '../Portal';
import { stVars } from '../theme/z-index.st.css';

import { classes } from './Popover.st.css';

interface IProps
  extends Omit<
    React.ComponentProps<typeof PopoverTPA>,
    'onClickOutside' | 'shown'
  > {
  content: React.ReactNode;
  children: React.ReactNode;

  isOpen?: boolean;
  onClose(): void;
}

export function Popover(props: IProps) {
  const { children, className, onClose, content, isOpen, ...rest } = props;

  const container = usePortalContainer();

  return (
    <PopoverTPA
      fixed
      shown={isOpen}
      appendTo={container}
      onClickOutside={onClose}
      disableClickOutsideWhenClosed
      className={cls(classes.root, className)}
      zIndex={stVars.zIndex_POPOVER as unknown as number}
      {...rest}
    >
      <PopoverTPA.Element>{children}</PopoverTPA.Element>
      <PopoverTPA.Content>{content}</PopoverTPA.Content>
    </PopoverTPA>
  );
}

Popover.displayName = 'Popover';
Popover.defaultProps = {
  placement: 'bottom-end',
};
