import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';

import { List } from 'wui/List';
import { Fade } from 'wui/Fade';

import { selectAnswersStatus } from 'store/members/selectors';
import type { IGroupMember } from 'store/members/types';

import { useController } from 'common/context/controller';

import {
  GroupQuestion,
  GroupQuestionSkeleton,
} from 'common/components/GroupMembership/dialogs/GroupQuestionsDialog/GroupQuestion';

interface IMemberAnswersProps {
  groupId: string;
  member: IGroupMember;
}

export function MemberAnswers(props: IMemberAnswersProps) {
  const { groupId, member } = props;

  const { members$ } = useController();
  const status = useSelector(
    selectAnswersStatus(member.siteMemberId as string),
  );

  useEffect(() => {
    if (!member.answers?.length) {
      members$.fetchAnswers(groupId, member.siteMemberId as string);
    }
  }, [member.siteMemberId, groupId]);

  return (
    <SwitchTransition>
      <Fade key={String(status.loading)} unmountOnExit>
        {status.loading ? (
          <List gap="SP2">
            <GroupQuestionSkeleton />
            <GroupQuestionSkeleton />
            <GroupQuestionSkeleton />
            <GroupQuestionSkeleton />
          </List>
        ) : (
          <List gap="SP2">
            {member.answers?.map((answer, index) => (
              <GroupQuestion
                disabled
                index={index + 1}
                key={answer.id}
                question={answer.question}
                required={answer.required}
                value={answer.answer}
              />
            ))}
          </List>
        )}
      </Fade>
    </SwitchTransition>
  );
}
