import React from 'react';
import { useSelector } from 'react-redux';

import { groupsSettingsParams } from 'settings/groups/settings';
import { useSettings } from '@wix/tpa-settings/react';

import type { IGroup } from 'store/groups';
import { selectIsJoinedGroupMember } from 'store/groups/selectors';

import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { Skeleton } from 'wui/Skeleton';
import { Show } from 'wui/Show';
import { ListItemIcon } from 'wui/ListItemIcon';

import { JoinedGroupListItem } from './JoinedGroupListItem';
import { SuggestedGroupListItem } from './SuggestedGroupListItem';

import { classes } from './GroupListItem.st.css';

interface IGroupListItemProps {
  group: IGroup;
}

export function GroupListItem(props: IGroupListItemProps) {
  const { group } = props;

  const isJoined = useSelector(selectIsJoinedGroupMember(group.id as string));

  if (isJoined) {
    return <JoinedGroupListItem group={group} />;
  }

  return <SuggestedGroupListItem group={group} />;
}

export function GroupListItemSkeleton() {
  const settings = useSettings();

  return (
    <ListItem disablePadding>
      <Show if={settings.get(groupsSettingsParams.showImage)}>
        <ListItemIcon>
          <Skeleton variant="rect" width={40} height={40} />
        </ListItemIcon>
      </Show>
      <ListItemText
        title={
          <Skeleton variant="text" width="80%" className={classes.title} />
        }
        subtitle={<Skeleton variant="text" width="90%" />}
      />
    </ListItem>
  );
}
