import React from 'react';
import cls from 'classnames';

import { useSettings } from '@wix/tpa-settings/react';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';

import settingsParams from 'Groups/settingsParams';

import { AspectRatio } from 'wui/AspectRatio';
import { Card } from 'wui/Card';
import { CardActions } from 'wui/CardActions';
import { CardContent } from 'wui/CardContent';
import { CardHeader } from 'wui/CardHeader';
import { Skeleton } from 'wui/Skeleton';
import { Show } from 'wui/Show';
import { Button } from 'wui/Button';

import { classes as theme } from 'Groups/styles.st.css';
import { classes } from './GroupGridItem.st.css';

export function GroupGridItemSkeleton() {
  const settings = useSettings();
  const imageRatio = settings.get(settingsParams.imageRatio);

  return (
    <Card>
      <Show if={settings.get(settingsParams.showImage)}>
        <AspectRatio aspectRatio={imageRatio}>
          <Skeleton variant="rect" width="100%" height="100%" />
        </AspectRatio>
      </Show>
      <CardContent className={classes.content}>
        <CardHeader
          title={<Skeleton className={theme.groupTitle} variant="text" />}
          subtitle={
            <Skeleton className={theme.groupInfo} variant="text" width="50%" />
          }
        />
      </CardContent>
      <Show if={settings.get(groupsListWidgetSettingsParams.showButton)}>
        <CardActions className={cls(classes.actions, classes.hidden)}>
          <Button>&nbsp;</Button>
        </CardActions>
      </Show>
    </Card>
  );
}

GroupGridItemSkeleton.displayName = 'GroupGridItemSkeleton';
